<ng-container *ngIf="articles$ && articles$.length > 0; else noArticles">
  <carousel [noWrap]="config.deactivateLoop"
            [showIndicators]="config.indicators"
            [isAnimated]="config.fade"
            class="article--slider">
    <slide *ngFor="let article of articles$">
      <div class="card article">
        <div class="card-body mt-1 d-inline-flex flex-column">
          <div class="article--info d-flex align-items-center">
            <div class="article--user">
              <img
                [src]="figure(article.user.look)" [alt]="article.user.username" />
            </div>
            <span>{{ article.user.username }}</span>
          </div>

          <div class="article--thumb mt-2">
            <img [src]="article.image" [alt]="article.title + ' - ' + article.description">

            <div class="article--date">
              <p class="date mb-2">
                <span>{{ article.created_at | date: 'd' : undefined : locale }}</span>
                {{ article.created_at | date: 'MMMM' : undefined : locale }}
              </p>
              <p class="time">{{ article.created_at | date: 'hh:mm' : undefined : locale }}</p>
            </div>
          </div>

          <div class="d-inline-flex flex-column pb-1">
            <p class="article--title">{{ article.title }}</p>
            <span
              class="article--description w-100">{{ (article.description.length > 90) ?
              (article.description | slice:0:90) + '..' : (article.description) }}
          </span>
          </div>

          <div class="d-inline-flex flex-row w-100">
            <div class="align-items-center flex-fill d-inline-flex flex-row">
              <div class="article--likes-icon mr-2">
                <i class="sprite sprite--thumb"></i>
              </div>
              <strong>{{ article.likes }}</strong>

              <span class="ml-5 mr-2"><strong>{{ article.comments }}</strong></span>
              <div class="article--comments-icon">
                <i class="sprite sprite--article-slider-comments"></i>
              </div>
            </div>
            <a [routerLink]="['/articles', article.id + '-' + article.slug]" class="btn btn-danger align-self-end w-25"
               [translate]="'DASHBOARD.SLIDER.ARTICLES.READ_MORE'"></a>
          </div>
        </div>
      </div>
    </slide>
  </carousel>
</ng-container>

<ng-template #noArticles>
  <div class="card article">
    <div class="card-body d-inline-flex flex-column">
      <div class="article--info d-flex align-items-center">
        <div class="article--user">
          <img id="frank" src="assets/images/articles/frank_15.gif" alt="Frank">
        </div>
        <span>Frank</span>
      </div>

      <div class="article--thumb mt-2">
        <img src="assets/images/articles/topstory.gif" [alt]="'DASHBOARD.SLIDER.ARTICLES.DESCRIPTION' | translate"/>
      </div>

      <div class="d-inline-flex flex-column pb-2">
        <p class="article--title" [translate]="'DASHBOARD.SLIDER.ARTICLES.TITLE'"></p>
        <span class="article--description w-100" [translate]="'DASHBOARD.SLIDER.ARTICLES.DESCRIPTION'"></span>
      </div>
    </div>
  </div>
</ng-template>
