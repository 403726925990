<div class="row">
  <div class="col-12 mb-3">
    <div class="card">
      <div class="d-flex flex-row p-4">
        <div id="sprite--container" class="mr-2">
          <i class="sprite sprite--articles"></i>
        </div>

        <div class="d-inline-flex flex-column article--info">
          <span class="article--title flex-fill mb-1" [translate]="'ARTICLES.ARTICLE.ARTICLES.TITLE'"></span>
          <span class="article--description flex-fill" [translate]="'ARTICLES.ARTICLE.ARTICLES.DESCRIPTION'"></span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 mb-3" *ngFor="let article of articles$">
    <a [routerLink]="'/articles/' + article.id + '-' + article.slug">
      <div class="card card--article cursor-pointer">
        <div class="d-flex flex-row p-4">
          <img class="mr-3" [src]="article.image" [alt]="article.title" [title]="article.title" />

          <div class="d-inline-flex flex-column article--info">
            <span class="article--title mb-1 flex-fill">{{ article.title }}</span>
            <span class="article--description flex-fill">{{ article.description }}</span>
          </div>
          <div class="d-inline-flex align-items-center m-auto mr-2">
            <i class="sprite sprite--comments-length mt-1 mr-2"></i>
            <span>{{ article.comments }}</span>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
