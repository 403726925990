// Don't forget to rename this file to environment.ts

export const environment = {
  production: true,

  app: {
    url: 'https://dev.habbo.ac',

    // API
    endpoint: 'https://api.habbo.ac',

    // Website
    hotelName: 'HabboAC',
    title: 'HabboAC ~ ',

    // Images
    imager: 'https://habbo.city/habbo-imaging/avatarimage?figure=',
    badgeParts: 'https://EXAMPLE/c_images/Badgeparts/generated/',
    album1584: 'https://EXAMPLE/c_images/album1584/',

    camera: {
      images: '',
      thumbnails: 'https://EXAMPLE/gamedata/camera/thumbnails/thumbnail_',
    },

    // Language
    defaultLang: 'en',
    useLang: 'de',

    // Components
    components: {
      dashboard: {
        hero: {
          date: 'dd.MM.yyyy',
          time: 'HH:mm',
        },
      },

      articles: {
        date: 'dd MMM yyyy',
      },

      article: {
        date: 'dd.MM.yyyy',
        time: 'HH:mm',
      },

      community: {
        guild: {
          date: 'dd.MM.yyyy',
        },
      },

      employees: {
        date: 'dd.MM.yyyy',
      },
    },
  },
};

export const client = {
  configurationUrl: 'https://nitro.habbo.ac/configuration.json',
  style: 'https://nitro.habbo.ac/styles.d2bd73a935d88e93be0f.css',
  runtime: 'https://nitro.habbo.ac/runtime.91a02d1be9f8a4fe75ea.js',
  vendor: 'https://nitro.habbo.ac/vendor.1bee09e1054447a4d942.js',
  main: 'https://nitro.habbo.ac/main.1b5e4204e4856c982847.js',
  polyfills: 'https://nitro.habbo.ac/polyfills.5b2bb0157376d34211f5.js',
};
