
<div class="container">
  <a class="navbar-brand" routerLink="/">
    <img src="../../../../../assets/images/logo.png" alt='{{ hotelName }}' title='{{ hotelName }}'/>
  </a>

  <button class="navbar-toggler" (click)="toggle()" type="button" data-toggle="collapse" data-target="#navbar"
          aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbar">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link icon icon--dashboard" routerLink='dashboard' routerLinkActive="active">
          Dashboard
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link icon icon--articles" routerLink="articles" routerLinkActive="active"
           [translate]="'NAVIGATOR.NEWS'">
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link icon icon--community" routerLink="community" routerLinkActive="active"
           [translate]="'NAVIGATOR.COMMUNITY'"></a>
      </li>

      <li class="nav-item">
        <a class="nav-link icon icon--employees" routerLink="employees" routerLinkActive="active"
           [translate]="'NAVIGATOR.EMPLOYEES'">
        </a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a id="user--dropdown" [style.backgroundImage]="'url('+ look + ')'"  class="nav-link dropdown-toggle" href="#"
           role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ username }}
        </a>
        <div class="dropdown-menu" aria-labelledby="user--dropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="logout">Logout</a>
        </div>
      </li>
    </ul>
  </div>
</div>
