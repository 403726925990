<div class="container">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar"
          aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbar">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item dropdown">
        <a id="habbo--look" class="nav-link d-flex align-items-center">
          <img [src]="look()" [alt]="user.username"/>
        </a>
      </li>
      <li class="nav-item info--item">
        <div class="nav-link d-flex flex-column justify-content-center user--info">
          <span class="user--info-header" [translate]="'NAVIGATION.CREDITS'"></span>
          <div class="d-inline-flex flex-row align-items-center">
            <img src="assets/images/icons/credits.png" class="mr-1 img-fluid image-pixelated" height="22" width="22" [alt]="'NAVIGATION.CREDITS' | translate" />
            <span class="user--info-value">{{ user.credits | numberSuffix: 2.0 }}</span>
          </div>
        </div>
      </li>
      <li class="nav-item info--item">
        <div class="nav-link d-flex flex-column justify-content-center user--info">
          <span class="user--info-header" [translate]="'NAVIGATION.DIAMONDS'"></span>
          <div class="d-inline-flex flex-row align-items-center">
            <img src="assets/images/icons/diamonds.png" class="mr-1 img-fluid image-pixelated"  height="22" width="22" [alt]="'NAVIGATION.DIAMONDS' | translate" />
            <span class="user--info-value">{{ diamonds | numberSuffix: 2.0 }}</span>
          </div>
        </div>
      </li>

      <li class="nav-item info--item">
        <div class="nav-link d-flex flex-column justify-content-center user--info">
          <span class="user--info-header" [translate]="'NAVIGATION.DUCKETS'"></span>
          <div class="d-inline-flex flex-row align-items-center">
            <img src="assets/images/icons/duckets.png" class="mr-1 img-fluid image-pixelated" height="22" width="22" [alt]="'NAVIGATION.DUCKETS' | translate" />
            <span class="user--info-value">{{ duckets | numberSuffix: 2.0 }}</span>
          </div>
        </div>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item d-flex align-items-center h-100 justify-content-center">
        <span class="nav-link active mr-2" [innerHTML]="'DASHBOARD.HERO.COUNTER' | translate: { online: this.counter, name: this.name }"></span>
      </li>
      <li class="nav-item">
        <a routerLink="/client" class="btn btn-success py-2 px-xxl-5 px-xl-5 px-l-5 px-md-3 px-sm-3" [translate]="'DASHBOARD.HERO.ENTER'"></a>
      </li>
      <li class="nav-item">
        <ares-language-selector class="px-2 mx-2"></ares-language-selector>
      </li>
    </ul>
  </div>
</div>
