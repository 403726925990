<div class="card d-flex flex-row">
  <img class="employee-figure" [src]="figure()" [alt]="employee$.username" />

  <div class="d-inline-flex flex-column justify-content-center flex-fill text-ellipsis">
    <span class="employee-username text-ellipsis">{{ employee$.username }}</span>
    <i class="employee-motto text-ellipsis">{{ employee$.motto }}</i>
  </div>

  <div class="flex-shrink d-inline-flex justify-content-end pt-2 pr-4">
    <i class="sprite sprite--date mr-1 mt-1"></i>
    {{ (employee$.last_online | amFromUnix) | amLocale:locale | amTimeAgo }}
  </div>

  <img class="rank-badge" [src]="badgePath(badge$)" [alt]="name$" [tooltip]="name$" placement="right" [adaptivePosition]="false" />
</div>
