<div class="d-flex flex-row p-4">
  <img class="mr-3" [src]="badge(guild$?.badge)" (error)="error($event)" [alt]="guild$?.name" [title]="guild$?.name" />

  <div class="flex-fill d-inline-flex flex-column text-ellipsis justify-content-center">
    <strong id="group--name" class="mb-0 text-ellipsis pr-2">{{ guild$?.name }}</strong>
    <span id="group--description" class="text-ellipsis pr-3">{{ guild$?.description }}</span>
  </div>

  <div class="d-inline-flex justify-content-center align-items-center">
    <strong id="group--members" class="pr-1">{{ guild$?.member_count }}</strong>
    <i class="sprite sprite--members"></i>
  </div>
</div>
