<ng-container *ngIf="articles$ && articles$.length > 0; else noArticles">
  <carousel [noWrap]="config.deactivateLoop"
            [showIndicators]="config.indicators"
            [isAnimated]="config.fade"
            class="article--slider">
    <slide *ngFor="let article of articles$">
      <a [routerLink]="['/articles', article.id + '-' + article.slug]">
        <div class="card article">
          <div class="card-body d-inline-flex flex-column">
            <div class="article--info d-flex align-items-center">
              <div class="article--user">
                <img
                  [src]="figure(article.user.look)"
                  [alt]="article?.user.username" />
              </div>
              <span>{{ article?.user.username }}</span>
            </div>

            <div class="article--thumb mt-2">
              <img [src]="article?.image">
            </div>

            <div class="d-inline-flex flex-column pb-2">
              <p class="article--title">{{ article?.title }}</p>
              <span
                class="article--description w-100">{{ (article?.description.length > 70) ? (article?.description | slice:0:70) + '..' : (article?.description) }}</span>
            </div>

            <div class="d-inline-flex flex-row w-100">
              <div class="align-items-center flex-fill d-inline-flex flex-row">
                <div class="article--likes-icon mr-2">
                  <i class="sprite sprite--thumb"></i>
                </div>
                {{ article?.likes }}

                <span class="ml-5 mr-2">{{ article?.comments }}</span>
                <div class="article--comments-icon">
                  <i class="sprite sprite--article-slider-comments"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </slide>
  </carousel>
</ng-container>

<ng-template #noArticles>
  <div class="card article">
    <div class="card-body d-inline-flex flex-column">
      <div class="article--info d-flex align-items-center">
        <div class="article--user">
          <img id="frank" src="../../../../../assets/images/articles/frank_15.gif">
        </div>
        <span>Frank</span>
      </div>

      <div class="article--thumb mt-2">
        <img src="../../../../../assets/images/articles/topstory.gif">
      </div>

      <div class="d-inline-flex flex-column pb-2">
        <p class="article--title" [translate]="'DASHBOARD.SLIDER.ARTICLES.TITLE'"></p>
        <span class="article--description w-100" [translate]="'DASHBOARD.SLIDER.ARTICLES.DESCRIPTION'"></span>
      </div>
    </div>
  </div>
</ng-template>
