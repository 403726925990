<ares-layout-client-buttons [url]="previousUrl"></ares-layout-client-buttons>

<ares-layout-client-disconnected
  [disconnected]="isDisconnected"
  (reload)="reload()"
></ares-layout-client-disconnected>
<ares-layout-client-active-session
  [active]="isSessionActive"
  (load)="overrideSession()"
></ares-layout-client-active-session>
