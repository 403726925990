<div class="d-inline-flex flex-row align-items-center mb-4">
  <i class="sprite sprite--comments mr-3"></i>

  <div class="d-inline-flex flex-column">
    <h4 class="mb-0" [translate]="'COMMUNITY.GUILD.GUESTBOOK.TITLE'"></h4>
    <span [translate]="'COMMUNITY.GUILD.GUESTBOOK.DESCRIPTION'"></span>
  </div>
</div>

<div id="entries" class="row" infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
      [scrollWindow]="false">
  <div class="col-12 mb-3 entry" *ngFor="let value of entries$">
    <div class="d-flex justify-content-start">
      <img class="align-self-start" [src]="look(value.user.look)" [class.offline]="value.user.online == 0" [alt]="value.user.username" />

      <div class="flex-grow-1 d-inline-flex flex-column">
        <div class="d-inline-flex flex-row">
          <strong class="pb-1 pt-2">{{ value.user.username }}</strong>
        </div>

        <span class="pb-3 mr-2 w-100">{{ value.content }}</span>
      </div>

      <div class="align-self-end d-inline-flex flex-row pb-2">
        <div [class.vote--active]="upVoteExists(value.id)" (click)="upVote(value)"
              class="comment--vote d-inline-flex flex-row align-items-center cursor-pointer mr-2">
          <i class="sprite sprite--like d-inline-flex mr-2"></i>
          {{ value.likes }}
        </div>

        <div [class.vote--active]="downVoteExists(value.id)" (click)="downVote(value)"
              class="comment--vote d-inline-flex flex-row cursor-pointer align-items-center mr-2">
          <i class="sprite sprite--dislike d-inline-flex mr-2"></i>
          {{ value.dislikes }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-center align-items-center px-4 py-3">
  <button class="btn btn-danger w-100 h-25" (click)="openModal(template)" [translate]="'COMMUNITY.GUILD.GUESTBOOK.BUTTON'"></button>
</div>


<ng-template class="h-100 justify-content-center" #template>
  <div class="modal-body d-flex flex-column justify-content-center">
    <div class="d-inline-flex flex-row mb-3">
      <i class="mx-4 mt-3 sprite sprite--comments"></i>
      <div class="d-inline-flex flex-column">
        <h4 class="mt-3 mb-0 modal--title" [translate]="'COMMUNITY.GUILD.GUESTBOOK.MODAL.TITLE'"></h4>
        <span class="modal--description" [translate]="'COMMUNITY.GUILD.GUESTBOOK.MODAL.DESCRIPTION'"></span>
      </div>
      <button type="button" class="close" (click)="modalRef.hide()"></button>
    </div>

    <div class="container d-inline-flex flex-column mt-4">
      <form [formGroup]="guestbookForm" (submit)="onSubmit()" id="comment--form">
        <div class="form-group" id="comment--textarea">
          <textarea class="form-control" formControlName="entry" placeholder="...."></textarea>
        </div>

        <button type="submit" class="btn btn-danger mt-3 w-100" [translate]="'COMMUNITY.GUILD.GUESTBOOK.MODAL.BUTTON'"></button>
      </form>
    </div>
  </div>
</ng-template>
