<div class="card friends">
  <div class="card-body d-flex flex-column">
    <div class="d-inline-flex align-items-center">
      <span class="title flex-fill" [translate]="'DASHBOARD.FRIENDS.TITLE'"></span>

      <div id="search" class="input-group bg-white w-50">
        <input class="form-control" placeholder="Suchen..." type="search" [maxLength]="12" [(ngModel)]="searchName" />

        <div class="input-group-text bg-white" id="search-icon">
          <fa-icon icon="search"></fa-icon>
        </div>
      </div>
    </div>

    <div id="friends" class="row" infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         (scrolled)="onScroll()"
         [scrollWindow]="false">
      <ng-container *ngIf="(friends$ | searchFriend: searchName).length === 0;">
        <div class="d-inline-flex flex-column h-100 w-100 justify-content-center">
          <h3 class="align-self-center"><fa-icon icon="unlink"></fa-icon></h3>
          <h4 class="align-self-center" [innerHTML]="'DASHBOARD.FRIENDS.NOT_FOUND' | translate: { username: searchName }"></h4>
        </div>
      </ng-container>

      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12" *ngFor="let friend of friends$ | searchFriend: searchName as result">
        <div class="card friend mb-2 d-flex flex-row align-items-center" [class.offline]="friend?.online < 1">
          <img [src]="look(friend?.look, friend?.online === 1 ? true : false)" [alt]="friend?.username" />

          <div class="user-info d-inline-flex flex-column text-ellipsis">
            <span class="username text-ellipsis mb-0">{{ friend?.username }}</span>
            <span class="motto text-ellipsis">{{ (!friend?.motto) ? motto : friend?.motto }}</span>
          </div>
        </div>
      </div>

      <div class="col-12 d-flex justify-content-center" [@fade]="state">
        <img src="../../../../../assets/images/loading.gif" />
      </div>
    </div>
  </div>
</div>
