import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ares-list-delete',
  templateUrl: './list-delete.component.html',
  styleUrls: ['./list-delete.component.scss']
})
export class ListDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
